// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-categories-jsx": () => import("./../src/templates/Categories.jsx" /* webpackChunkName: "component---src-templates-categories-jsx" */),
  "component---src-templates-index-jsx": () => import("./../src/templates/Index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-page-jsx": () => import("./../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../src/templates/Tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

